body {
    // -webkit-font-smoothing: antialiased;
    // text-rendering: optimizeLegibility;
    // -moz-osx-font-smoothing: grayscale;
    font-family: 'Gotham';
    font-weight: 300;
    font-style: normal;
    color: $dark;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: 'Gotham';
    font-weight: bold;
    font-style: normal;
    color: $text-color;
}

h1,
.h1 {
    font-size: 90px;
    font-weight: 100;
}

h2,
.h2 {
    font-size: 90px;
    font-weight: 100;
}

h3,
.h3 {
    font-size: 36px;
    font-weight: 100;
}

h4,
.h4 {
    font-size: 28px;
}

h5,
.h5 {
    font-size: 24px;
}

h6,
.h6 {
    font-size: 22px;
}

p {
    font-size: 18px;
}

a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    color: $dark;
    transition: all 0.35s;
}

a:hover,
a:focus {
    color: $primary;
}

.text-secoundary{
    color: $secoundary;
}

@include media-breakpoint-down(xl) {
    h1, .h1 {
        font-size: 64px;
    }

    h2, .h2 {
        font-size: 64px;
    }

    h3, .h3 {
        font-size: 32px;
    }

    h4, .h4 {
        font-size: 24px;
    }

    h5, .h5 {
        font-size: 22px;
    }

    h6, .h6 {
        font-size: 20px;
    }
}
@include media-breakpoint-down(lg) {
    h1, .h1 {
        font-size: 48px;
    }

    h2, .h2 {
        font-size: 48px;
    }

    h3, .h3 {
        font-size: 28px;
    }

    h4, .h4 {
        font-size: 24px;
    }

    h5, .h5 {
        font-size: 22px;
    }

    h6, .h6 {
        font-size: 18px;
    }
    p{
        font-size: 16px;
    }
}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size: 30px;
    }

    h2, .h2 {
        font-size: 24px;
    }
    h3, .h3 {
        font-size: 22px;
    }
    p{
        font-size: 14px;
    }
}