// 
// layout: footer
// 
.footer{
    background-color: #375e9c;
    padding: 45px 10px;
    .footer-brand{
        text-align: center;
        img{
            max-width: 190px;
        }
    }
    .footer-data-container{
        text-align: center;
    }
    .footer-data{
        padding: 30px 20px;
        margin: 50px 0px 40px 0px;
        border-top: 1px solid rgba($white, 0.2);
        border-bottom: 1px solid rgba($white, 0.2);
        display: inline-flex;
        flex-wrap: wrap;
    }
    .footer-data-item{
        text-align: left;
        padding: 0 40px;
        h5, p{
            color: $white;
        }
        h5{
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        p{
            margin: 0;
            font-size: 15px;
        }
    }

    .footer-copyright{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        p, a{
            margin: 0;
            color: $white;
            font-size: 15px;
        }
        a{
            color: #94b7ee;
            margin-left: 10px;
            &:hover{
                color: $secoundary;
            }
        }
    }
}


@include media-breakpoint-down(md) {
    .footer{
        .footer-data-item{
            margin-bottom: 40px;
            h5{
                margin-bottom: 10px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer{
        .footer-data{
            display: block;
            .footer-data-item{
                padding: 0;
                text-align: center;
            }
        }
        .footer-copyright{
            display: block;
            text-align: center;
            a{
                margin: 10px 0;
            }
        }
    }
}