// 
// base: icons
// 
@font-face {
    font-family: 'icomoon';
    src: url(../fonts/icomoon.eot?1cobi);
    src: url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?1cobi) format('truetype'), url(../fonts/icomoon.woff?1cobi) format('woff'), url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-social-care:before {
    content: "\e93a";
}

.icon-file-empty:before {
    content: "\e924";
}

.icon-files-empty:before {
    content: "\e925";
}

.icon-file-text2:before {
    content: "\e926";
}

.icon-file-picture:before {
    content: "\e927";
}

.icon-file-music:before {
    content: "\e928";
}

.icon-file-play:before {
    content: "\e929";
}

.icon-file-video:before {
    content: "\e92a";
}

.icon-file-zip:before {
    content: "\e92b";
}

.icon-copy:before {
    content: "\e92c";
}

.icon-paste:before {
    content: "\e92d";
}

.icon-link:before {
    content: "\e9cb";
}

.icon-attachment:before {
    content: "\e9cd";
}

.icon-eye:before {
    content: "\e9ce";
}

.icon-eye-plus:before {
    content: "\e9cf";
}

.icon-google-drive:before {
    content: "\ea8f";
}

.icon-facebook1:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-instagram1:before {
    content: "\ea92";
}

.icon-whatsapp:before {
    content: "\ea93";
}

.icon-telegram:before {
    content: "\ea95";
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-rss2:before {
    content: "\ea9c";
}

.icon-youtube:before {
    content: "\ea9d";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-twitch:before {
    content: "\ea9f";
}

.icon-vimeo:before {
    content: "\eaa0";
}

.icon-vimeo2:before {
    content: "\eaa1";
}

.icon-flickr:before {
    content: "\eaa3";
}

.icon-flickr2:before {
    content: "\eaa4";
}

.icon-flickr3:before {
    content: "\eaa5";
}

.icon-flickr4:before {
    content: "\eaa6";
}

.icon-linkedin2:before {
    content: "\eaca";
}

.icon-lastfm:before {
    content: "\eacb";
}

.icon-pinterest:before {
    content: "\ead1";
}

.icon-pinterest2:before {
    content: "\ead2";
}

.icon-file-pdf:before {
    content: "\eadf";
}

.icon-file-openoffice:before {
    content: "\eae0";
}

.icon-file-word:before {
    content: "\eae1";
}

.icon-file-excel:before {
    content: "\eae2";
}

.icon-libreoffice:before {
    content: "\eae3";
}

.icon-arrow-right:before {
    content: "\e900";
}

.icon-arrow-down:before {
    content: "\e901";
}

.icon-arrow-left:before {
    content: "\e902";
}

.icon-arrow-up:before {
    content: "\e903";
}

.icon-burger:before {
    content: "\e904";
}

.icon-check:before {
    content: "\e905";
}

.icon-close-st:before {
    content: "\e906";
}

.icon-close:before {
    content: "\e907";
}

.icon-coach:before {
    content: "\e908";
}

.icon-cross-out:before {
    content: "\e909";
}

.icon-deal:before {
    content: "\e90a";
}

.icon-decision-making:before {
    content: "\e90b";
}

.icon-down-arrow:before {
    content: "\e90c";
}

.icon-download-s:before {
    content: "\e90d";
}

.icon-download:before {
    content: "\e90e";
}

.icon-elearning:before {
    content: "\e90f";
}

.icon-email:before {
    content: "\e910";
}

.icon-envelope:before {
    content: "\e911";
}

.icon-facebook-letter-logo:before {
    content: "\e912";
}

.icon-facebook-logo:before {
    content: "\e913";
}

.icon-facebook:before {
    content: "\e914";
}

.icon-flow-chart:before {
    content: "\e915";
}

.icon-full-screen:before {
    content: "\e916";
}

.icon-house:before {
    content: "\e917";
}

.icon-instagram-logo:before {
    content: "\e918";
}

.icon-instagram:before {
    content: "\e919";
}

.icon-magnifying-glass:before {
    content: "\e91a";
}

.icon-mail-envelope:before {
    content: "\e91b";
}

.icon-minus:before {
    content: "\e91c";
}

.icon-next:before {
    content: "\e91d";
}

.icon-pdf:before {
    content: "\e91e";
}

.icon-phone-call:before {
    content: "\e91f";
}

.icon-pin:before {
    content: "\e920";
}

.icon-play:before {
    content: "\e921";
}

.icon-plus:before {
    content: "\e922";
}

.icon-presentation:before {
    content: "\e923";
}

.icon-prev:before {
    content: "\e92e";
}

.icon-rank:before {
    content: "\e92f";
}

.icon-school:before {
    content: "\e930";
}

.icon-search-st:before {
    content: "\e931";
}

.icon-search:before {
    content: "\e932";
}

.icon-smartphone:before {
    content: "\e933";
}

.icon-teamwork:before {
    content: "\e934";
}

.icon-tr-down:before {
    content: "\e935";
}

.icon-tr-left:before {
    content: "\e936";
}

.icon-tr-right:before {
    content: "\e937";
}

.icon-tr-up:before {
    content: "\e938";
}

.icon-zoom:before {
    content: "\e939";
}