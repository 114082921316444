//
// Bredcrumbs
//
.breadcrumb {
    background-color: transparent;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    align-items: center;
    &-item,
    &-item a {
        font-size: 18px;
        color: $text-color;
        transition: all 0.3s;
        font-weight: normal;
        font-style: normal;
        font-family: 'Gotham Book';
    }
    &-item a:hover {
        color: $secoundary;
    }
    &-item+&-item::before {
        padding-right: 12px;
        padding-left: 6px;
        content: "-";
        font-weight: normal;
        font-style: normal;
        font-family: 'Gotham Book';
    }
    &-item.active {
        color: $secoundary;
    }
}

@include media-breakpoint-down(md) {
    .breadcrumb{
        display: none;
    }
}
