// 
// Article
// 

article{
    h1, h2, h3, h4, h5, h6{
        margin: 40px 0;
    }
    p{
        margin: 40px 0;
        font-weight: 300;
        font-size: 18px;
    }
    
    .article-download{
        padding: 0px 15% 40px 15%;
        margin-top: 40px;
        h6{
            padding-left: 30px;
            margin: 0;
            margin-bottom: 25px;
        }
    }
}

.article-single{
    .article-content{
        padding: 40px 15%;
    }
    p{
        font-size: 18px;
    }
    .article-meta{
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 0;
    }
    h2:first-of-type{
        margin-top: 0;
    }
}


@include media-breakpoint-down(lg) {
    .article-single p, article p{
        font-size: 16px;
    }
}

@include media-breakpoint-down(md) {
    article{
        h1, h2, h3, h4, h5, h6{
            margin: 30px 0;
        }
        p{
            margin: 20px 0;
        }
        .article-download{
            padding: 0px;
            margin-top: 20px;
            h6{
                margin-bottom: 15px;
            }
        }
    }

    .article-single .article-content{
        padding: 40px 0;
    }
}

@include media-breakpoint-down(sm) {
    article{
        h1, h2, h3, h4, h5, h6{
            margin: 20px 0;
        }
        p{
            margin: 20px 0;
            font-size: 14px;
        }

        .article-download{
            margin-top: 30px;
            h6{
                padding-left: 10px;
                margin: 0;
                margin-bottom: 15px;
            }
        }
    }

    .article-single p, article p{
        font-size: 14px;
    }
}


