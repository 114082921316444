// colors
$dark:             #232323;
$black:            #000;
$white:            #fff;
$primary:          #1a60ae;
$secoundary:       #ff0000;
$text-color:       #3c4759;

$gray-100:         #f9f9fa;
$gray-200:         #cccccc;






