// 
// pager
// 
.pager {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 40px 0;
    ul.pagination {
        margin: 0 30px;
        padding: 40px 30px;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
    }
    .pager-link:hover:not(.disableed) a {
        color: $primary;
    }
    .pager-link.disableed {
        opacity: 0.4;
    }
    .pager-arrow-left, .pager-arrow-right{
        color: $text-color;
        font-size: 26px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        &:hover{
            color: $secoundary;
        }
    }

    .pager-back {
        display: block;
        margin: 0 30px;
        padding: 40px 30px;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        color: rgba($dark, 0.6);
        line-height: 1.2;
        transition: all 0.3s;
        &:hover{
            color: $secoundary;
        }
    }
}

.pager-right{
    justify-content: flex-end;
}


@include media-breakpoint-down(xl) {
    .pager{
        margin: 30px 0;
        ul.pagination {
            padding: 30px 20px;
        }
        .pager-back{
            padding: 30px 20px;
        }
    }
}

@include media-breakpoint-down(md) {
    .page-title.page-title-pager{
        padding-bottom: 10px;
    }
}

@include media-breakpoint-down(sm) {
    .pager-flex{
        .pager-text{
            display: none;
        }
    }

    .pager{
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }

    .page-title-pager, .page-title.page-title-pager{
        padding-right: 0;
    }
}