// 
// page: contact
// 
ul.contact-data{
    padding: 0;
    margin: 0;
    display: flex;
    > li{
        flex: 0 0 50%;
        padding: 0px 30px;
    }
}

.contact-item{
    display: flex;
    margin-bottom: 60px;
    .contact-item-icon{
        flex:0 0 80px;
        font-size: 54px;
    }
    .contact-item-content{
        h6{
            padding: 4px 0px 20px 0px;
        }
        p{
            margin: 0;
        }
    }
}

.form-container{
    margin-left: -40px;
    margin-right: -40px;
    padding: 70px 0px;
    .form-title{
        padding-left: 40px;
        text-transform: uppercase;
        margin-bottom: 60px;
        font-weight: 500;
    }
}

@include media-breakpoint-down(xl) {
    .contact-item{
        margin-bottom: 20px;
        .contact-item-content{
            h6{
                padding: 4px 0px 10px 0px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {
    ul.contact-data{
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    ul.contact-data{
        padding-top: 20px;
        > li{
            padding: 0;
        }
    }

    .contact-item{
        margin-bottom: 20px;
        .contact-item-content{
            h6{
                padding: 5px 0px 5px 0px;
            }
        }
    }
}


