// 
// Sidebar
// 
.sidebar {
    padding: 35px 0px 140px 30px;
    position: relative;
    width: 100%;
    border-left: 1px solid $gray-200;
    .widget + .widget{
        margin-top: 45px;
    }
    .widget-title{
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: bold;
    }
    .title-small{
        margin-bottom: 15px;
    }
}

// 
// widget menu
// 
ul.widget-menu{
    padding: 0;
    margin: 0;
    li{
        margin-bottom: 4px;
    }
    li:last-child{
        margin-bottom: 0;
    }
    li a{
        font-size: 18px;
        display: inline-block;
        position: relative;
        color: $text-color;
    }
    li a.active, li a:hover{
        color: $secoundary;
    }
}

@include media-breakpoint-down(md) {
    .sidebar{
        border:0px;
        padding-left: 0;
    }
}


