//
// FIX olw mobile scroll
//
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: auto;
    touch-action: auto;
}

.owl-carousel{
    .owl-dots{
        display: none;
    }
    .owl-nav{
        display: flex;
        justify-content: center;
        padding: 30px 0;
    }
    .btn-arrow-left{
        border-right: 1px solid $gray-200;
    }
    .btn-arrow:hover{
        color: $secoundary;
    }
}
.owl-news{
    position: relative;
    z-index: 30;
    margin-top: -60px;
    margin-bottom: -60px;
    .owl-nav{
        position: absolute;
        right: 0;
        transform: translateX(120%);
        top: 40px;
    }
}

.owl-partners-container{
    position: relative;
    overflow: hidden;
    .owl-partners{
        padding-top: 90px;
        position: relative;
        position: relative;
        right: -2px;
        overflow: hidden;
        .owl-container{
            margin-right: -2px;
        }
        .owl-nav{
            position: relative;
            left: -8px;
        }
        .owl-item{
            border-right: 1px solid $gray-200;
        }
    }
}



.article-gallery{
    position: relative;
    padding: 20px 0;
    position: relative;
    margin: 0;
    &:before{
        content: "";
        position: absolute;
        top: 40%;
        bottom: 0;
        left: -50vw;
        right: -50vw;
        background-color: $gray-100;
        z-index: -1;
    }
}
@include media-breakpoint-down(xl) {
    .owl-partners-container .owl-partners{
        padding-top: 10px;
    }
}

@include media-breakpoint-down(lg) {
    .owl-news{
        margin: 0;
    }
}


@include media-breakpoint-down(md) {
    .owl-news{
        .owl-nav{
            padding: 0;
            position: static;
            transform: translateX(0%);
        }
    }
}

@include media-breakpoint-down(sm) {
    .article-gallery{
        padding-top: 0;
    }

    .owl-partners-container{
        .owl-partners{
            padding-top: 0px;
            right: 0;
            overflow: hidden;
            .owl-container{
                margin-right: 0px;
            }
            .owl-nav{
                position: static;
                padding: 0;
            }
            .owl-item{
                border:0px;
            }
        }
    }
}


