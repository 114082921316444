// 
// page: eam
// 

ul.team-list{
    display: flex;
    flex-wrap: wrap;
    padding: 0 10%;
    > li{
        flex: 0 0 50%;
        margin-bottom: 40px;
    }
}

.team-item{
    padding-left: 15%;
    padding-right: 10%;
    position: relative;
    .team-image{
        text-align: right;
        max-width: 300px;
    }
    .team-border{
        position: absolute;
        height: 82%;
        right: 15%;
        left: 0%;
        bottom: 0%;
        border:2px solid $secoundary;
        z-index: -1;
        opacity: 0;
        transition: all 0.3s;
    }
    .team-card{
        padding: 45px 40px;
        background-color: $white;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.0);
        text-align: center;
        transition: all 0.3s;
        h5{
            font-size: 24px;
            font-weight: bold;
            color: $primary;
            margin-bottom: 20px;
            transition: all 0.3s;
        }
    }
    .team-more{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        opacity: 0;
        transform: translateY(50%);
        transition: all 0.3s;
    }
}

.team-item:hover{
    .team-card{
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    }
    .team-border{
        bottom: 10%;
        opacity: 1;
    }
    .team-card{
        h5{
            color: $secoundary;
        }
    }
    .team-more{
        opacity: 1;
        transform: translateY(0%);
    }
}

.team-item-description{
    padding: 50px 0 40px 0px;
    h6{
        margin-bottom: 40px;
    }
}

.team-item-single{
    padding-bottom: 40px;
    h3{
        margin: 40px 0;
    }
    .group{
        margin-bottom: 40px;
        p{
            margin: 0;
        }
    }
}


@include media-breakpoint-down(xl) {
    
    .team-item{
        .team-image{
            max-width: 200px;
        }
        .team-card{
            padding: 30px 20px;
            h5{
                font-size: 18px;
                margin-bottom: 10px;
            }
            p{
                font-size: 14px;
            }
        }
        .team-more{
            padding-top: 10px;
        }
    }
}



@include media-breakpoint-down(lg) {
    ul.team-list{
       padding: 0;
    }
    .team-item-description{
        h6{
            margin-bottom: 15px;
        }
    }
}

@include media-breakpoint-down(sm) {
    ul.team-list {
        display: block;
    }
    .team-item{
        padding-right: 0;
        .team-card{
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
        }
        .team-border{
            bottom: 10%;
            opacity: 1;
        }
        .team-card{
            h5{
                color: $secoundary;
            }
        }
        .team-more{
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .team-item-single{
        h3{
            margin-bottom: 10px;
        }
        .group{
            margin-bottom: 15px;
        }
    }

    .team-item-description{
        padding: 20px 0;
    }
}


