// 
// Cards
// 
.card {
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    position: relative;
    margin-bottom: 30px;
    &-body {
        padding: 15px 40px;
    }
    .card-date, .card-title{
        transition: all 0.3s;
    }
    &-title{
        margin-bottom: 25px;
        display: block;
        text-shadow:1px 0 0 transparent;
        transition: all 0.15s;
        font-weight: bold;
        font-size: 18px;
    }
    &-meta {
        font-size: 12px;
        font-weight: 300;
    }
}

.card:hover{
    .card-date, .card-title{
        color: $secoundary;
    }
}


@include media-breakpoint-down(xl) {
    .card{
        &-body {
            padding: 10px 15px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .card{
        &-title{
            font-size: 16px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .card{
        margin-bottom: 10px;
    }
}