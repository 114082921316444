//
// Pagination
//
.pagination {
    display: inline-flex;
    margin: 0;
    border-radius: 0px;
    li a {
        padding: 5px 7px;
        font-size: 12px;
        font-weight: 700;
        transition: all 0.3s;
        color: $text-color;
        &:hover {
            color: $secoundary;
        }
    }
    li a.active {
        color: $secoundary;
    }
}