// 
// aside
// 

@keyframes anim-main-menu {
    from{
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes anim-rect {
    0%{
        opacity: 0;
        transform: translateX(20px);
    }

    20% {
        opacity: 0.5;
        transform: translateX(0px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.aside{
    position: fixed;
    z-index: 999;
    right: -160px;
    top:0px;
    bottom: 0;
    width: 240px;
    background-color: #375e9c;
    padding: 20px 30px;
    
    transition: all .5s cubic-bezier(.645,.045,.355,1);
    
    .cd-middle{
        position: absolute;
        left:0px;
        right: 0px;
        top:50%;
        transform: translateY(-50%);
		display: none;
    }

    ul.main-menu, ul.social, ul.menu-shape{
        padding: 0;
        margin: 0;
    }

    ul.main-menu{
        display: none;
        padding-top: 120px;
    }

    ul.main-menu li{
        display: block;
        animation: anim-main-menu 0.3s linear; animation-fill-mode:both;
    }


    ul.main-menu > li:nth-child(1) {  animation-delay: 0.32s; }
    ul.main-menu > li:nth-child(2) {  animation-delay: 0.40s; }
    ul.main-menu > li:nth-child(3) {  animation-delay: 0.48s; }
    ul.main-menu > li:nth-child(4) { animation-delay:  0.56s; }
    ul.main-menu > li:nth-child(5) { animation-delay:  0.64s; }
    ul.main-menu > li:nth-child(6) { animation-delay:  0.72s; }
    ul.main-menu > li:nth-child(7) { animation-delay:  0.80s; }
    ul.main-menu > li:nth-child(8) { animation-delay:  0.88s; }

    ul.main-menu li a{
        display: block;
        color:#fff;
        font-size: 11px;
        text-transform: uppercase;
        border-top: 1px solid rgba(206, 206, 206, 0.25);
        padding: 14px 0px;
        transition: all 0.3s ease-in-out;
        transition-delay: 0s;
    }

    ul.main-menu li a:hover{
        text-decoration: none;
        padding-left: 12px;
    }

    ul.main-menu li:last-child a{
        border-bottom: 1px solid rgba(206, 206, 206, 0.25);
    }
    
    ul.menu-shape > li{
        display: block;
        animation: anim-rect 0.8s linear; animation-fill-mode:both;
        opacity: 0;
    }

    ul.menu-shape > li > a{
        display: block;
        width: 80px;
        padding: 4px 0px;
        padding-left: 36px;
    }

    ul.menu-shape > li > a .rect{
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fff;
        position: relative;

    }

    ul.menu-shape > li:nth-child(1) {  animation-delay: 0.32s; }
    ul.menu-shape > li:nth-child(2) {  animation-delay: 0.40s; }
    ul.menu-shape > li:nth-child(3) {  animation-delay: 0.48s; }
    ul.menu-shape > li:nth-child(4) { animation-delay:  0.56s; }
    ul.menu-shape > li:nth-child(5) { animation-delay:  0.64s; }
    ul.menu-shape > li:nth-child(6) { animation-delay:  0.72s; }
    ul.menu-shape > li:nth-child(7) { animation-delay:  0.80s; }
    ul.menu-shape > li:nth-child(8) { animation-delay:  0.88s; }
    
    ul.menu-shape > li > a .hover-cd{
        position: absolute;
        left: -7px;
        background-color: #2477bc;
        transform: translateX(-100%);
        color:#fff;
        text-transform: uppercase;
        font-size: 12px;
        padding: 10px 16px;
        font-weight: 700;
        opacity: 0;
        height: 37px;
        width: 70px;
        white-space: nowrap;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
    }

    ul.menu-shape > li > a .hover-cd:after{
        content: "";
        position: absolute;
        right: -7px;
        top:-4px;
        height: 100%;
        bottom: 0px;
        width: 7px;
        background-color: #1e3042;
        transform: skewY(-50deg);
    }
    
    ul.menu-shape > li > a:hover .hover-cd{
        opacity: 1;
        height: 37px;
        width: 140px;
        visibility: visible;
    }
}

.aside-button{
    position: fixed;
    top: 26px;
    right: 26px;
    z-index: 9999;
}

.aside-socials{
    position: fixed;
    z-index: 9999;
    right: 26px;
    bottom: 10px;

    ul.social{
        padding: 0;
        margin: 0;
    }
    ul.social li{
        display: block;
        margin-bottom: 10px;
    }
    ul.social li a{
        display: block;
        background-color: $white;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        position: relative;
        [class^="icon"]{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
        }
    }
	ul.social li a:hover{
		color:$secoundary;
    }
}

.aside.open{
    right:0px;
    background-color: #1a60ae;

    ul.main-menu{
        display: block;
    }
    
    ul.menu-shape{
        display: none;
    }
}

body.home-page .aside .cd-middle{
	display: block;
}



.aside-mobile{
    display: none;
}
@include media-breakpoint-down(lg) {
    body.menu-is-active{
        overflow: hidden;
    }
    .aside{
        overflow-y: auto;
    }

    body.home-page .aside .cd-middle{
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .aside{
        right: -240px;
        ul.main-menu{
            padding-top: 60px;
        }
    }
    .aside-socials{
        opacity: 0;
        visibility: hidden;
        transform: translateX(100%);
        transition: all 0.3s;
    }
    body.menu-is-active{
        .aside-socials{
            opacity: 1;
            visibility: visible;
            transform: translateX(0%);
        }
    }

    .aside-mobile{
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #5580c3;
        padding: 10px 15px;
        z-index: 99;
        .aside-brand{
            max-width: 70px;
        }
    }

    .aside-button{
        position: fixed;
        top: 20px;
        right: 15px;
    }
}