// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.btn {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    padding: 14px 45px;
    color: $dark;
    border: 1px solid transparent;
    outline: 0px;
    box-shadow: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 0px;
    text-align: center;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
       outline: none;
        
    }
}

// sizes
.btn-lg{
    padding: 15px 35px;
    font-size: 15px;
}

// alternate
.btn-white{
    border-color: rgba( $white, 0.6 );
    color:rgba( $white, 0.6 );
    background-color: transparent;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled),
    &.active:not(:disabled):not(.disabled) {
        background-color: transparent;
        color: $white;
        border-color: $white;
    }
}

.btn-secoundary{
    border-color: $green;
    background-color: $green;
    color: $white;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled),
    &.active:not(:disabled):not(.disabled) {
        background-color: $blue;
        color: $white;
        border-color: $blue;
    }
}

.btn-secoundary-outline{
    border-color: $secoundary;
    background-color: transparent;
    color: $secoundary;
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled),
    &.active:not(:disabled):not(.disabled) {
        background-color: $secoundary;
        color: $dark;
        border-color: $secoundary;
    }
}

.btn-arrow-cd{
    background-color: transparent;
    outline: none;
    border:0px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 10px;
    .arrow{
        margin-right: 12px;
    }
}

.btn-arrow-cd:hover{
    .arrow{
        background-color: $secoundary;
    }
    .more{
        color: $secoundary;
    }
}

.btn-arrow{
    color: #3c4759;
    font-size: 26px;
    position: relative;
    padding: 20px 30px;
}

.arrow{
    display: inline-block;
    padding: 5px 20px;
    background-color: $primary;
    color: $white;
    font-size: 24px;
    line-height: 1;
    transition: all 0.3s;
    [class^="icon"]{
        position: relative;
        top: 2px;
    }
    &:hover{
        background-color: $secoundary;
    }
}

.more{
    display: inline-block;
    text-transform: uppercase;
    color: $text-color;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 10px;
    &:hover{
        color: $secoundary;
    }
}

@include media-breakpoint-down(xl) {
    .btn-arrow{
        padding: 15px 20px;
    }
}
