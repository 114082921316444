//
// Image holder
//
.image-holder {
    display: block;
    position: relative;
    z-index: 10;
    overflow: hidden;
    &-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-200;
        background-size: cover;
        background-position: 50%;
    }
    &-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 20%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-content{
        position: absolute;
        z-index: 2;
        left: 45px;
        top: 40px;
        h3{
            color: $white;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    &-icon{
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 18px;
        color: $dark;
        opacity: 0;
        transform: scale(0.4);
        transition: all 0.3s;
    }
}

.image-holder:hover{
    .image-holder-icon{
        transform: scale(1);
        opacity: 1;
    }
}

//
// Sizes
//
.image-holder {
    &-xs {
        padding-bottom: 42%;
    }
    &-sm {
        padding-bottom: 56%;
    }
    &-md {
        padding-bottom: 72.5%;
    }
    &-lg {
        padding-bottom: 82.5%;
    }
    &-lg-v {
        padding-bottom: 135%;
    }
    &-xl {
        padding-bottom: 160%;
    }
    &-xxl {
        padding-bottom: 180%;
    }
    &-squere {
        padding-bottom: 100%;
    }
    &-lg-double{
        padding-bottom: 169.5%;
    }
}

//
// Overlay
//
.overlay {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.35s;
        background-color: rgba($black, 0.25);
        opacity: 1;
    }
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.35s;
        background-color: rgba($secoundary, 0.45);
        opacity: 0;
    }
}

.overlay:hover{
    &:before{
        opacity: 0;
    }
    &:after{
        opacity: 1;
    }
}

@supports (mix-blend-mode: overlay) {
    .overlay:after{
        mix-blend-mode: multiply;
        background-color: rgba($secoundary, 1);
    }
}

.holder-zoom{
    .image-holder-bg{
        transform: scale(1);
        transition: all 0.45s;
    }
    &:hover{
        .image-holder-bg{
            transform: scale(1.1);
        }
    }
}


@include media-breakpoint-down(lg) {
    @supports (mix-blend-mode: overlay) {
        .overlay:after {
            mix-blend-mode: multiply;
            background-color: rgba($primary, 1);
        }
    }
}

