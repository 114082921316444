.list-post{
    position: relative;
    margin-bottom: 0;
    &:before{
        content: "";
        position: absolute;
        top: 45%;
        bottom: 0;
        left: -50vw;
        right: -50vw;
        background-color: $gray-100;
        z-index: -1;
    }
}