// 
// List
// 
ul,
ol {
    @extend .list-unstyled;
}

// list base
ul.list-base {
    font-size: 15px;
    li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
        &:before {
            content: "-";
            position: absolute;
            left: 0;
        }
    }
}

// list bullet
ul.list-bullet {
    padding: 40px 40px;
    margin: 40px -40px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    font-size: 14px;
    li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 20px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 15px;
            height: 15px;
            background-color: $white;
            border:4px solid $gray-700;
            border-radius: 50%;
        }
    }
    li:last-child{
        margin-bottom: 0;
    }
}

//
// Lists decimal
//
ul.list-decimal{
    counter-reset: counter-decimal;
    font-size: 16px;
    > li{
        position: relative;
        padding-left: 35px;
        margin-bottom: 10px;
        &:before{
            position: absolute;
            left: 0;
            top: -1px;
            content: counter(counter-decimal, decimal)  ".";
            counter-increment: counter-decimal;
            font-weight: 600;
            color: $gray-300;
        }
    }
}

//
// list download
//
ul.list-download{
    > li{
        margin-bottom: 4px;
    }
    > li:last-child{
        margin-bottom: 0;
    }
    h6{
        padding-left: 30px;
    }
}

.download-item{
    padding: 20px 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-100;
    
    .download-text, .download-link{
        font-weight: 300;
        font-size: 18px;
        transition: all 0.3s;
    }
    
    .download-link{
        display: flex;
        padding: 6px 0;
        align-items: center;
        font-size: 13px;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        [class^="icon"]{
            margin-left: 12px;
            font-size: 26px;
            position: relative;
            top: -4px;
        }
    }
    &:hover{
        .download-text, .download-link{
            color: $secoundary;
        }
    }
}

// 
// list list-feature
// 
ul.list-feature{
    margin: 50px 0;
    > li{
        padding: 25px 30px 15px 30px;
        border-bottom: 1px solid $gray-200;
    }
    > li:last-child{
        border: 0px;
    }
}

.feature-item{
    display: flex;
    .feature-icon{
        flex: 0 0 90px;
        font-size: 54px;
        color: #375e9c;
    }
    .feature-content{
        h6{
            margin: 0;
            margin-bottom: 10px;
        }
        p{
            margin: 0;
        }
    }
}


@include media-breakpoint-down(lg) {
    ul.list-bullet li{
        margin-bottom: 10px;
    }
}

@include media-breakpoint-down(sm) {
    .download-item{
        padding: 10px;
        display: block;
        .download-text, .download-link{
            font-size: 16px;
        }
        .download-link{
           margin-top: 10px;
           justify-content: flex-end;
        }
    }
}

