// 
// page: home
// 

.home-page{
    .page-brand{
        padding: 0;
        padding-right: 80px;
        position: absolute;
        left: 0;
        right: 0;
        top: 100px;
        z-index: 55;
    }
}

//section
.section{
    position: relative;
}
.section-title{
    padding: 40px 0;
    h3{
        padding-left: 12%;
    }
}

.section-title-flex{
    display: flex;
    justify-content: space-between;
}


// main swiper
.main-swiper{
    position: relative;
    background-color: $dark;
    .swiper-slide{
        position: relative;
    }
    .swiper-slide-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    
    .swiper-slide-bg{
        background-size: cover;
        background-position: 50%;
        z-index: 10;
    }
    .swiper-slide-content{
        box-sizing: border-box;
        position: relative;
        z-index: 11;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 240px 0;
        min-height: 100vh;
    }

    .swiper-slide-overlay{
        .swiper-slide-bg{
            &:before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(#e4e5ea, 0.85);
            }
        }
    }

    h4{
        font-size: 30px;
        font-weight: 300;
        color: $primary;
        
    }
    h3{
        font-size: 55px;
        font-weight: 300;
        color:$primary;
    }
    h2{
        font-size: 55px;
        color: $secoundary;
        font-weight: bold;
        text-align: right;
    }
    h5{
        color: $secoundary;
        font-size: 30px;
        font-weight: 300;
        text-align: right;
    }
    .cite{
        font-size: 120px;
        color: rgba(#080808, 0.4);
        text-align: right;
    }
    
    h2, h3, h4, h5, .cite {
        opacity: 0;
        transform: translateX(40px);
    }
    h2 {
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.25s;
    }
    h3 {
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.35s;
    }
    h4 {
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.45s;
    }
    h5 {
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.55s;
    }
    .cite {
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.65s;
    }
    
    .swiper-main-scroll{
        position: absolute;
        z-index: 12;
        left: 0;
        right: 0;
        bottom: 120px;
        a{
            color: #030303;
            display: inline-flex;
            transform: rotate(-90deg);
            transform-origin: 50%;
            align-items: center;
            letter-spacing: 2px;
            font-size: 13px;
            font-weight: 300;
            line-height: 1;
            transition: all 0.3s;
            [class^="icon"]{
                position: relative;
                top: 1px;
                font-size: 20px;
                margin-right: 12px;
            }

            &:before{
                content: "";
                position: absolute;
                left: -20px;
                width: 100px;
                top: -30px;
                bottom: -30px;
                background-color: #375e9c;
                z-index: -1;
                transition: all 0.3s;
            }

            &:hover{
                color: $blue;
            }
        }

        a:hover{
            color: $white;
            &:before{
                width: 170px;
            }
        }
    }
}

.main-swiper .swiper-slide-active {
    h2, h3, h4, h5, .cite {
        opacity: 1;
        transform: translateX(0px);
    }
}


// why us
ul.list-grid {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    > li{
        flex: 0 1 33.333%;
    }
}

.grid-item{
    overflow: hidden;
    position: relative;
    .image-holder-xl{
        padding-bottom: 120%;
    }
    .grid-item-image{
        position: relative;
        z-index: 1;
    }
    .grid-item-title{
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.3s;
        transform: translateY(0px);
        opacity: 1;
    }
    .grid-item-content{
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        opacity: 0;
        transform: translateY(20%);
        visibility: hidden;
        transition: all 0.3s;
    }

    h3{
        font-size: 28px;
        padding: 20px 10%;
        color: $white;
        text-align: center;
    }
    p{
        font-size: 16px;
        color: #8a8a8a;
        padding: 0 40px;
    }
}

.grid-item:hover{
    .grid-item-title{
        opacity: 0;
        transform: translateY(110%);
    }
    .grid-item-content{
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
    }
    .overlay-whyUs{
        &:before{
            opacity: 0;
        }
        &:after{
            opacity: 1;
        }
    }
}

.overlay-whyUs{
    position: relative;
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.35s;
        background-image: url(../img/bg-why.png);
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        opacity: 0.5;
    }
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.35s;
        background-color: rgba(#020710, 0.95);
        opacity: 0;
    }
}


@supports (mix-blend-mode: multiply) {
    .overlay-whyUs:before{
        mix-blend-mode: multiply;
        opacity: 1;
    }
}

// parallax
.parallax {
    background-color: $dark;
    position: relative;
    overflow: hidden;
    .parallax-image {
        position: absolute;
        z-index: 11;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
        background-attachment: fixed;
        opacity: 1;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($primary, 0.65);
        }
    }
    .parallax-content {
        position: relative;
        z-index: 22;
        color: $white;
        padding: 240px 0;
        h3, p, .more{
            color: $white;
        }
        h3{
            font-size: 72px;
            font-weight: 100;
            span{
                font-weight: 300;
            }
        }
        p{
            font-size: 24px;
            font-weight: 100;
        }
    }
}

// news
.section-news{
    position: relative;
    padding-bottom: 60px;
    &:after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 335px;
        bottom: 0;
        background-color: $gray-100;
        z-index: -1;
    }
    .section-title{
        padding-bottom: 20px;
    }
}
.card-promo-news{
    .card-body{
        padding-bottom: 40px;
    }
}

// partners
.owl-partners{
    .image-holder-md{
        padding-bottom: 50%;
    }
}
.slogan{
    h3, h4{
        color: $primary;
    }
    h3{
        font-size: 46px;
    }
    h4{
        font-size: 50px;
        text-align: right;
        font-family: 'Gotham Book';
        font-weight: normal;
        font-style: normal;
    }
}
.partners-slogan-1{
    h3{
        padding-left: 40px;
    }
}

.partners-slogan-2{
    padding: 50px 0px 100px 0px;
    display: inline-block;
    padding-left: 20%;
}


@supports (mix-blend-mode: multiply) {
    .parallax .parallax-image:after{
        mix-blend-mode: multiply;
        background-color: rgba($primary, 1);
        opacity: 0.7;
    }
}


@include media-breakpoint-down(xl) {
    .home-page{
        .page-brand{
            top: 20px;
        }
    }

    .section-title{
        padding: 25px 0;
    }

    // main-swiper
    .main-swiper {
        .swiper-slide-content{
            padding: 160px 0;
        }
        h3, h2{
            font-size: 40px;
        }
        .cite{
            font-size: 80px;
        }

        .swiper-main-scroll{
            bottom: 90px;
        }
    }

    // grid item
    .grid-item .image-holder-xl {
        padding-bottom: 110%;
    }

    // why us
    .grid-item{
        h3{
            font-size: 22px;
        }
        h3{
            padding: 20px 5%;
        }
        p{
            padding: 0 5%;
        }
    }

    // parallax
    .parallax{
        .parallax-content{
            padding: 160px 0;
            h3{
                font-size: 46px;
            }
        }
    }

    // partners
    .slogan{
        h3{
            font-size: 32px;
        }
        h4{
            font-size: 36px;
        }
    }
    .partners-slogan-2{
        padding-top: 0;
    }
}


@include media-breakpoint-down(lg) {
    //why us
    .grid-item{
        h3{
            color: $text-color;
        }
        p{
            font-size: 14px;
        }
        .grid-item-title{
            display: none;
        }
        .grid-item-content{
            position: static;
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
        }
    }

    .overlay-whyUs:after{
        content: none;
    }
    //parallax{
    .parallax{
        .parallax-image{
            background-attachment: scroll;
        }
    } 

    // partners
    .slogan{
        h3{
            font-size: 30px;
        }
        h4{
            font-size: 32px;
        }
    }
}


@include media-breakpoint-down(md) {
    // why us
    ul.list-grid{
        display: block;
    }
    .grid-item{
        .grid-item-content{
            padding: 0 20px;
        }
        .image-holder-xl{
            padding-bottom: 120%;
        }
        .image-holder-bg{
            background-position: top center;
        }
    }

    // parallax
    .parallax .parallax-content{
        h3{
            font-size: 36px;
        }
        p{
            font-size: 20px;
        }
    }

    // news
    .section-news{
        padding-bottom: 0;
        &:after{
            content: none;
        }
    }
    .card-promo-news{
        display: none;
    }
}


@include media-breakpoint-down(sm) {
    // section
    .section-title{
        padding: 20px 0;
        h3{
            padding-left: 0;
        }
    }
    .section{
        padding-top: 60px;
        margin-top: -60px;
    }
    .section-title-flex{
        display: block;
        h2{
            margin-bottom: 10px;
        }
        .btn-arrow-cd{
            padding-left: 0;
        }
    }
    // main swiper
    .main-swiper{
        .swiper-slide-content{
            padding: 50px 0;
            min-height: 80vh;
        }
        h4{
            font-size: 16px;
        }
        h3, h5{
            font-size: 20px;
            text-align: left;
        }
        h2{
            font-size: 26px;
            text-align: left;
        }
        .cite{
            font-size: 40px;
            text-align: left;
        }
        .swiper-main-scroll{
            display: none;
        }
    }
    
    // parallax
    .parallax .parallax-content{
        h3{
            font-size: 26px;
        }
        p{
            font-size: 18px;
        }
    }

    // partners
    .slogan{
        h3, h4{
            text-align: center;
            padding: 0;
        }
        h3{
            font-size: 24px;
        }
        h4{
            font-size: 26px;
        }
    }

    .partners-slogan-2{
        padding: 20px 0;
    }
}


