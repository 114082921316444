// 
// layout: page
// 
.page{
    overflow: hidden;
    padding-right: 80px;
    &-brand{
        padding: 40px 0px 50px 0px;
        img{
            max-width: 190px;
        }
    }
    &-title{
        padding-bottom: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        h1{
            margin: 0;
        }
        .pager{
            margin-top: 0;
        }
    }
    &-title-pager{
        justify-content: flex-end;
        padding-bottom: 0;
        padding-right: 30px;
        h1{
            display: none;
        }
        .pager{
            margin-top: 0;
        }
    }
}

.bg-gray{
    background-color: $gray-100;
    padding: 40px 0;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -50vw;
        right: -50vw;
        background-color: $gray-100;
        z-index: -1;
    }
}


@include media-breakpoint-down(xl) {
    .page{
        &-title{
            padding-bottom: 40px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .page{
        padding-right: 0;
        padding-top: 80px;
        &-title{
            padding-bottom: 10px;
        }
    }

    .home-page{
        .page{
            padding-top: 50px;
        }
    }

    .page-title .pager{
        margin: 10px 0;
    }

    .bg-gray{
        padding: 20px 0;
    }

    .page-brand{
        padding: 20px 0;
        display: none;
    }
}